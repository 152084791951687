import { Container } from 'unstated-typescript'
import Discovery, { assertContainerHasInitializedDiscovery } from './discovery'
import type DiscoveryType from '@soccerwatch/discovery'
import { Competition } from '@soccerwatch/common'
import { getListCompetitionsByTeam } from '../api/api-competition'
import { postCompetition } from '../api/api-city'

type CompetitionState = {
  loadingData: boolean
  competitions: Array<Competition>
  competitionsByTeam: Record<string, Competition[]>
  discovery?: typeof DiscoveryType
}

export class CompetitionContainer extends Container<CompetitionState> {
  discovery?: typeof DiscoveryType
  constructor() {
    super()

    this.state = {
      loadingData: true,
      competitions: [],
      competitionsByTeam: {},
      discovery: undefined
    }

    Discovery.then((d) => {
      this.setState({ discovery: d })
    })
  }

  initialize() {}

  fetchCompetitionsByCompetitionList = async (competitionList: Competition[]) => {
    const competitions = JSON.parse(JSON.stringify(this.state.competitions)) as Competition[]
    const competitionsByTeam = JSON.parse(JSON.stringify(this.state.competitionsByTeam))
    // merge oldCompetitons with competitionList
    competitionList = competitionList.filter((el) => el != null)
    const ids = new Set(competitions.map((d) => d.RowKey))
    competitionList = competitionList.filter((el) => el != null)
    const competitionMerged = [...competitions, ...competitionList.filter((d) => !ids.has(d.RowKey))]
    let newT: any = {}
    competitionList.map((comp) => {
      comp.teamIds.map((teamId) => {
        if (!newT[teamId]) {
          newT = { ...newT, [teamId]: [comp] }
        } else {
          newT[teamId].push(comp)
        }

        newT[teamId] = newT[teamId].filter(function (item: any, pos: any, self: any) {
          return self.indexOf(item) == pos
        })
      })
    })

    await this.setState({
      competitions: competitionMerged,
      competitionsByTeam
    })
    return { competitions: competitionMerged, competitionsByTeam }
  }

  fetchCompetitionsOfTeam = async (teamId: string) => {
    assertContainerHasInitializedDiscovery(this)
    await this.setState({ loadingData: true })
    const competitionRes = await getListCompetitionsByTeam(teamId)
    const competitions = JSON.parse(JSON.stringify(this.state.competitions)) as Competition[]
    const competitionsByTeam = JSON.parse(JSON.stringify(this.state.competitionsByTeam)) as Record<
      string,
      Competition[]
    >
    competitionsByTeam[teamId] = competitionRes

    competitionRes.forEach((newComp) => {
      if (!competitions.find((comp) => comp.RowKey === newComp.RowKey)) {
        competitions.push(newComp)
      }
    })

    await this.setState({
      loadingData: false,
      competitions: competitions.concat(competitionRes),
      competitionsByTeam
    })
  }

  getCompetitionsOfTeam = (teamId: string) => {
    if (this.state.competitionsByTeam[teamId]) {
      return [...this.state.competitionsByTeam[teamId]]
    }
    console.warn('Could not find Competitions for Team with Id', teamId, ' in State. This should not happen!')
    return undefined
  }

  getCompetitionById = (competitionId: string) => {
    const competition = this.state.competitions.find((c) => c.RowKey === competitionId)
    if (competition) {
      return competition
    }
    console.warn('Could not find Competitions with Id', competitionId, 'in State. This should not happen!')
    return undefined
  }

  createCompetition = async (body: Partial<Competition>) => {
    assertContainerHasInitializedDiscovery(this)
    const res = await postCompetition(body)
    return res
  }
}

const competitionContainer = new CompetitionContainer()
export default competitionContainer
