import axios from 'axios'

import { Container } from 'unstated-typescript'
import { searchAndSortGetCallAs } from './serviceHelper'
import Discovery, { assertContainerHasInitializedDiscovery } from './discovery'
import UserLogin, { UserContainer } from './userContainer'
import type DiscoveryType from '@soccerwatch/discovery'
import { Contract, License, Role } from '@soccerwatch/common'
import { VideoContainer } from './videoContainerContracts'
import { AdvertisementContainer } from './advertisementContainer'
import { CameraContainer } from './cameraContainer'
import { ClubContainer } from './clubContainer'
import { TeamContainer } from './teamContainer'
import { CompetitionContainer } from './competitionContainer'
import {
  deleteLicenseByUserId,
  getContractById,
  getContractsMe,
  getLicensesOfContract,
  getUserById,
  getUserByMail,
  postAddUserToContract,
  postContract
} from '../api/api-user'

export interface ContractContingent {
  minutes: number
  validTo: string
  type: ContractContingentType
  transactionId: string
  canceled: boolean
}

export enum ContractContingentType {
  ONCE = 'ONCE',
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

export type UserData = {
  RowKey: string
  username: string
  thumbnail: string
  license: License[]
  myLicenses: boolean
  addNewUser?: boolean
}

type ContractState = {
  loadingData: boolean
  tryContractData: boolean
  contracts: Array<Contract>
  contractSubUser: Record<string, UserData[]>
  discovery?: typeof DiscoveryType
  activeContract: number
  lockContractSelect: boolean
  inTransferToContract?: number
}

export class ContractContainer extends Container<ContractState> {
  discovery?: typeof DiscoveryType
  videoContainer?: VideoContainer
  advertisementContainer?: AdvertisementContainer
  cameraContainer?: CameraContainer
  userContainer?: UserContainer
  teamContainer?: TeamContainer
  competitionContainer?: CompetitionContainer

  contractChangedListener: Record<
    string,
    { onChange: () => void; onBeforeChange: (index: number, activeContract: number) => void }
  > = {}

  constructor() {
    super()
    this.state = {
      loadingData: true,
      tryContractData: false,
      contracts: [],
      contractSubUser: {},
      discovery: undefined,
      activeContract: -1,
      lockContractSelect: false,
      inTransferToContract: undefined
    }
    Discovery.then((d) => {
      this.setState({ discovery: d })
    })
  }

  async initialize(
    videoContainer: VideoContainer,
    advertisementContainer: AdvertisementContainer,
    cameraContainer: CameraContainer,
    userContainer: UserContainer,
    clubContainer: ClubContainer,
    teamContainer: TeamContainer,
    competitionContainer: CompetitionContainer
  ) {
    // Check if Saved index exists in Localstorage, else Pass -1
    const savedIndex = parseInt((await window.localStorage.getItem('ContractIndex')) ?? '-1', 10)

    await this.fetchContractList(savedIndex)

    this.videoContainer = videoContainer
    this.advertisementContainer = advertisementContainer
    this.cameraContainer = cameraContainer
    this.userContainer = userContainer
    this.teamContainer = teamContainer
    this.competitionContainer = competitionContainer

    videoContainer.initialize(this)
    advertisementContainer.initialize(this)

    await cameraContainer.initialize(this, userContainer, videoContainer)
    await clubContainer.initialize(this, teamContainer, competitionContainer)
  }

  getHighestRelevantRoleForCurrentContract = () => {
    // * If state.inTransferToContract is undefined, current Contract is used automatically
    return this.getHighestRelevantRoleForContract(this.state.inTransferToContract)
  }

  getHighestRelevantRolesForCurrentContract = () => {
    // * If state.inTransferToContract is undefined, current Contract is used automatically
    return this.getHighestRelevantRolesForContract(this.state.inTransferToContract)
  }

  getHighestRelevantRoleForContract = (index?: number) => {
    if (!this.userContainer) {
      if (!this.state.loadingData) {
        console.error('<ContractContainer> UserContainer not Initualized. This should not happen!')
      }
      return Role.user
    }
    const contract = this.getContract(index)
    if (!contract) {
      console.error('<ContractContainer> No Contract, Should not Happen!')
      return Role.user
    }
    const highest = this.userContainer.getHighestRelevantRoleOfContract(contract.RowKey)
    return highest
  }

  getHighestRelevantRolesForContract = (index?: number) => {
    if (!this.userContainer) {
      if (!this.state.loadingData) {
        console.error('<ContractContainer> UserContainer not Initualized. This should not happen!')
      }
      return [Role.user]
    }
    const contract = this.getContract(index)
    if (!contract) {
      console.error('<ContractContainer> No Contract, Should not Happen!')
      return [Role.user]
    }
    const highest = this.userContainer.getHighestRelevantRolesOfContract(contract.RowKey)
    return highest
  }

  setLockContractSelect = (value: boolean) => {
    this.setState({ lockContractSelect: value })
  }

  subscribeToContractChanged = (
    name: string,
    onChange: () => void,
    onBeforeChange: (index: number, activeContract: any) => Promise<void>
  ) => {
    if (!this.contractChangedListener[name]) {
      this.contractChangedListener[name] = { onChange, onBeforeChange }
      return true
    }
    return false
  }

  unsubscribeFromContractChanged = (name: string) => {
    if (this.contractChangedListener[name]) {
      delete this.contractChangedListener[name]
      return true
    }
    return false
  }
  notifyOnBeforeChange = async (index: number, activeContract: any) => {
    const promises = Object.keys(this.contractChangedListener).map((name) => {
      return this.contractChangedListener[name].onBeforeChange(index, activeContract)
    })
    return await Promise.all(promises)
  }

  currentContractChanged = () => {
    Object.keys(this.contractChangedListener).forEach((name) => {
      this.contractChangedListener[name].onChange()
    })
  }

  setActiveContract = (contractId: string) => {
    const index = this.state.contracts.findIndex((c) => c.RowKey === contractId)
    if (index === -1) {
      return console.error(
        `<ContractContainer> Could not set contract ${contractId} as active contract. Contract not found in ContractList`
      )
    }
    this.setActiveContractByIndex(index)
  }

  checkInTransferToContract = () => {
    return this.state.inTransferToContract
  }

  setActiveContractByIndex = async (index: number) => {
    const contract = this.state.contracts[index]
    if (!contract) {
      console.error(
        `<ContractContainer> Could not set to Contract with Index ${index}: Contract does not exist. This should not happen.`
      )
      return
    }

    await this.setState({ loadingData: true, inTransferToContract: index })
    await this.notifyOnBeforeChange(index, this.checkInTransferToContract)

    if (index !== this.state.inTransferToContract) return

    await this.setState({ activeContract: index, inTransferToContract: undefined }, async () => {
      if (!this.state.contractSubUser[contract.RowKey]) {
        await this.setContractSubUser(index)
      }
      await this.setState({ loadingData: false })
      this.currentContractChanged()
      window.localStorage.setItem('ContractIndex', String(index))
    })
  }

  getContractById = (id: string) => {
    return this.state.contracts.find((c) => c.RowKey === id)
  }

  getCurrentContract = () => {
    return this.getContract()
  }

  getContract = (index?: number) => {
    const i = index ?? this.state.activeContract
    if (i < 0) {
      return undefined
    }
    return this.state.contracts[i]
  }

  userHasMultipleContracts() {
    return this.state.contracts.length > 1
  }

  fetchContractList = async (savedIndex = -1) => {
    assertContainerHasInitializedDiscovery(this)
    await this.setState({ loadingData: true })
    let contractsRes: Contract[] = []

    try {
      contractsRes = await getContractsMe()
    } catch (error) {
      console.error('get contracts:', error)
    }

    const activeContract =
      savedIndex >= 0 && savedIndex < contractsRes.length ? savedIndex : contractsRes.length > 0 ? 0 : -1

    contractsRes.sort(function (a, b) {
      return a.name.localeCompare(b.name)
    })

    await this.setState({ contracts: contractsRes, activeContract, tryContractData: true }, async () => {
      if (contractsRes.length > 0) {
        await this.setContractSubUser()
      } else {
        await this.setState({ loadingData: false, tryContractData: true })
      }
    })
  }

  getCheckUserMail = async (mail: string) => {
    return await getUserByMail(mail)
  }

  postDummyUpdate = async (body: { name: string; clubIds: string[]; RowKey: string }) => {
    assertContainerHasInitializedDiscovery(this)
    return await postContract(body)
  }

  updateCurrentContract = async () => {
    return this.updateContract()
  }

  updateContract = async (index?: number) => {
    assertContainerHasInitializedDiscovery(this)
    const contract = this.getContract(index)
    if (!contract) {
      console.error(
        '<Could not Update Contract with index',
        index,
        '. Contract Not found. This should not happen!'
      )
      return
    }
    const contracts = JSON.parse(JSON.stringify(this.state.contracts)) as Contract[]
    const contractIndex = contracts.findIndex((c) => c.RowKey === contract.RowKey)
    const callAs = searchAndSortGetCallAs([
      Role.admin,
      contractContainer.getHighestRelevantRoleForCurrentContract()
    ])
    const update = await getContractById(contract.RowKey, callAs)
    contracts[contractIndex] = update
    await this.setState({ contracts })
  }

  postUserSubscription = async (contractId: string, type: string, userId: string) => {
    const callAs = searchAndSortGetCallAs([
      Role.admin,
      contractContainer.getHighestRelevantRoleForCurrentContract()
    ])
    let result = false
    const body = {
      contractId: contractId,
      type: type,
      userId: userId,
      cameraId: '*',
      clubId: '*'
    }

    try {
      await postAddUserToContract(body, callAs)
      await this.setContractSubUser()
      result = true
    } catch (err) {
      result = false
    }

    return result
  }

  deleteUserSubscription = async (subId: string, userId: string) => {
    const callAs = searchAndSortGetCallAs([
      Role.admin,
      contractContainer.getHighestRelevantRoleForCurrentContract()
    ])
    await deleteLicenseByUserId(userId, subId, callAs)
    await this.setContractSubUser()
  }

  deleteUserSubscriptionList = async (subscriptionList: Array<any>) => {
    const callAs = searchAndSortGetCallAs([
      Role.admin,
      contractContainer.getHighestRelevantRoleForCurrentContract()
    ])

    try {
      for (let i = 0; i < subscriptionList.length; i++) {
        await deleteLicenseByUserId(subscriptionList[i].PartitionKey, subscriptionList[i].RowKey, callAs)
      }
      await this.setContractSubUser()
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  currentContractNeedsAdditionalData = () => {
    return this.contractNeedsAdditionalData()
  }

  contractNeedsAdditionalData = (index?: number) => {
    const contract = this.getContract(index)
    return Boolean(
      contract &&
        contract?.SLNeedsVerification &&
        (!contract.name || contract.name === '' || contract.clubIds.length <= 0)
    )
  }

  getUserForCurrentContract = () => {
    return this.getUserForContract()
  }

  getUserForContract = (index?: number) => {
    if (this.state.loadingData) {
      return []
    }
    const contract = this.getContract(index)
    if (!contract) {
      console.error('<ContractContainer> Requested Contract Index out of Bounds. This should not happen!')
      return []
    }
    if (!this.state.contractSubUser[contract.RowKey]) {
      console.error(
        '<ContractContainer> No Userdata for Contract',
        contract.RowKey,
        '. This should not happen!'
      )
    }
    return this.state.contractSubUser[contract.RowKey]
  }

  setContractSubUser = async (index?: number) => {
    assertContainerHasInitializedDiscovery(this)
    this.setState({ loadingData: true })
    const subUser = JSON.parse(JSON.stringify(this.state.contractSubUser)) as Record<string, UserData[]>

    const contract = this.getContract(index)
    if (!contract) {
      console.warn('<ContractContainer> No Active Contract Found')
      return
    }
    const licences = await getLicensesOfContract(contract.RowKey)
    const neededUserIds = licences.reduce((userIds, license) => {
      if (!userIds[license.PartitionKey]) {
        userIds[license.PartitionKey] = []
      }
      userIds[license.PartitionKey].push(license)
      return userIds
    }, {} as Record<string, License[]>)
    let cUser = [] as UserData[]
    for (let i in Object.keys(neededUserIds)) {
      const id = Object.keys(neededUserIds)[i]
      let user
      try {
        if (id !== UserLogin.state.user?.user_id) {
          user = (await axios.get(`https://storage.googleapis.com/shared-user-bucket/${id}/public.json`))
            .data as UserData
          user.RowKey = id
          user.license = neededUserIds[id]
          cUser.push(user)
        }
      } catch (error) {
        try {
          user = await getUserById(id)
          user.license = neededUserIds[id]
          user.RowKey = id
          cUser.push(user)
        } catch (err) {
          continue
        }
      }
    }
    subUser[contract.RowKey] = cUser
    await this.setState({
      loadingData: false,
      contractSubUser: subUser
    })
  }
}

const contractContainer = new ContractContainer()
export default contractContainer
